import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = ({isLoggedIn}) => {

  return (

    <div>
   

    

      </div>
      );
} 



export default Navbar;

const linkStyles = {
  activeLink: {
    color: "gray",
  },
  
  defaultLink: {
    textDecoration: "none",
    color: "white",
  },
};
import { useEffect, useState } from "react";
import axios from 'axios';
import React from "react";
import { NavLink, Link } from "react-router-dom";

function What_To_Eat() {
  const [answer, setAnswer] = useState([]);

  const fetchRecipes = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/recipes/random`);
      if (response.data && response.data.recipes) {
        setAnswer(response.data.recipes);
      } else {
        setAnswer([]); // Ensure answer is always an array
      }
    } catch (error) {
      console.error('Error fetching recipes:', error);
      setAnswer([]); // Ensure answer is always an array
    }
  };

  function handleWhatToEatButton() {
    fetchRecipes();
  }

  return (
    <div>

<header className="header_style">
        <div className="navigator">
          
          <div>
            <NavLink to={'/secret-page'} style={({ isActive }) => (
              isActive ? linkStyles.activeLink : linkStyles.defaultLink
            )}>Suggestions </NavLink>
          </div>

          <div>
            <NavLink to={'/search'} style={({ isActive }) => (
              isActive ? linkStyles.activeLink : linkStyles.defaultLink
            )}>Search</NavLink>
          </div>
          
          <div>
            <NavLink to={'/favorites'} style={({ isActive }) => (
              isActive ? linkStyles.activeLink : linkStyles.defaultLink
            )}>Favorites</NavLink>
          </div>

        </div>
      </header>




      <h1> Randomly Select </h1>
      <h2> Click For Random Choice </h2>
      <button className="button_for_random" onClick={handleWhatToEatButton}> Click </button>
      <div>
        {answer.length > 0 ? (
          answer.map((recipe, index) => (
            <div key={recipe.id} className="recipe_for_random">
                <Link to={`/recipe/${recipe.id}`}>
                  <h2>{recipe.title}</h2>
                  <img src={recipe.image} alt={recipe.title} />
                </Link>
              </div>
          ))
        ) : (
          <p>No recipes found</p>
        )}
      </div>
    </div>
  );
}

export default What_To_Eat;


const linkStyles = {
  activeLink: {
    color: "white",
    textDecoration: 'none'
  },
  defaultLink: {
    textDecoration: "none",
    color: "white",
  },
};
